<template>
  <div class="main">
    <el-card shadow="never" class="cus-card">
      <el-row>
        <div class="form-title">
          <el-radio-group v-model="checkIndex">
            <el-radio-button label="1"> {{ $t('ui_announcement') }}</el-radio-button>
            <el-radio-button label="2"> {{ $t('ui_wap_text_069') }}</el-radio-button>
            <el-radio-button label="3"> {{ $t('ui_internal_letter') }}</el-radio-button>
          </el-radio-group>
        </div>
        <el-col :span="24">
          <Public v-if="checkIndex == 1" />
          <Notice v-if="checkIndex == 2" />
          <Mail v-if="checkIndex == 3" />
        </el-col>
      </el-row>
    </el-card>
  </div>
</template>

<script lang="ts">
import {Options, Vue} from 'vue-class-component';
import Public from './components/public.vue';
import Notice from './components/notice.vue';
import Mail from './components/mail.vue';

@Options({
  components: {
    Public,
    Notice,
    Mail
  }
})
export default class Login extends Vue {
  checkIndex = 1;
}
</script>
<style lang="scss" scoped>
@import '../../../common/styles/element.scss';
.form-title {
  margin-bottom: 30px;
}
.main {
  border: 0;
  :deep(.el-radio-button__orig-radio:checked + .el-radio-button__inner),
  :deep(.el-checkbox__input.is-checked .el-checkbox__inner),
  :deep(.el-checkbox__input.is-indeterminate .el-checkbox__inner),
  :deep(.el-radio__input.is-checked .el-radio__inner) {
    background-color: $--color-primary;
    border-color: $--color-primary;
  }
  :deep(.el-radio-button__inner:hover),
  :deep(.el-checkbox__input.is-checked + .el-checkbox__label),
  :deep(.el-radio__input.is-checked + .el-radio__label) {
    color: $--color-primary;
  }
  :deep(.is-active .el-radio-button__inner:hover) {
    color: #fff;
  }
  :deep(.el-card.is-always-shadow) {
    box-shadow: unset;
    border: 0;
  }
}
</style>
