<template>
  <el-row v-if="!showDetail">
    <create-form
      :visible="showCreatFrom"
      :onCancel="() => (showCreatFrom = false)"
      :onSubmit="submitWrite"
    />
    <el-col :span="24">
      <el-form :inline="true" :model="formInline" class="demo-form-inline">
        <el-form-item :label="$t('msgCenter__date')">
          <el-date-picker
            type="daterange"
            v-model="formInline.date"
            range-separator="-"
            :start-placeholder="$t('msgCenter__start_date')"
            :end-placeholder="$t('msgCenter__end_date')"
            class="form-basic-width100"
            @change="transTime"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item :label="$t('msgCenter__status')">
          <el-select v-model="formInline.status" :placeholder="$t('msgCenter__all')">
            <el-option :label="$t('msgCenter__readed')" value="1"></el-option>
            <el-option :label="$t('msgCenter__notRead')" value="2"></el-option>
            <el-option :label="$t('msgCenter__sended')" value="3"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="getList">{{ $t('ui_search_for') }}</el-button>
          <el-button type="primary" @click="showCreatFrom = true">{{
            $t('ui_wap_text_055')
          }}</el-button>
        </el-form-item>
      </el-form>
    </el-col>
    <el-col :span="24">
      <div v-if="!loaded">
        <el-skeleton :rows="2" animated v-for="v in 5" :key="v" />
      </div>
      <div
        v-else
        class="msg-list disFlex"
        @click="jumpDetail(v.id, v.sender_id)"
        v-for="(v, i) in list"
        :key="i"
      >
        <div class="content">
          <dt>{{ v.title }}</dt>
          <dd>{{ v.status_text }}</dd>
        </div>
        <div class="time">{{ v.time }}</div>
      </div>
    </el-col>
    <div class="page">
      <el-pagination layout="prev, pager, next" :total="total" v-if="total > 0"> </el-pagination>
    </div>
  </el-row>
  <Content-detail
    v-else
    v-model="showDetail"
    :title="detail.title"
    :time="detail.time"
    :content="detail.content"
    :loaded="loaded"
  />
</template>

<script lang="ts">
/* eslint-disable @typescript-eslint/camelcase */
import {IMail, IMailResult} from '@/model';
import {Format, Server} from '@/services';
import {Options, Vue} from 'vue-class-component';
import createForm from './CreateForm.vue';
import {ElMessage} from 'element-plus';
import {ContentDetail} from '@/common/web';

interface ISendParams {
  page: number;
  // eslint-disable-next-line @typescript-eslint/camelcase
  page_limit: number;
  // eslint-disable-next-line @typescript-eslint/camelcase
  start_date?: string;
  // eslint-disable-next-line @typescript-eslint/camelcase
  end_date?: string;
  status?: string;
}
@Options({
  components: {
    createForm,
    ContentDetail
  }
})
export default class Public extends Vue {
  page = 1;

  limit = 10;

  total = 0;

  showDetail = false;

  showCreatFrom = false;

  list: Array<IMail> = [];

  loaded = false;
  // eslint-disable-next-line @typescript-eslint/camelcase
  start_date = '';
  // eslint-disable-next-line @typescript-eslint/camelcase
  end_date = '';

  detail = {
    title: '',
    time: '',
    // eslint-disable-next-line @typescript-eslint/camelcase
    content: ''
  };

  formInline = {
    date: '',
    status: ''
  };

  created() {
    this.getList();
  }
  transTime() {
    // eslint-disable-next-line @typescript-eslint/camelcase
    this.start_date = Format.formatYMDLine(new Date(this.formInline.date[0]));
    // eslint-disable-next-line @typescript-eslint/camelcase
    this.end_date = Format.formatYMDLine(new Date(this.formInline.date[1]));
  }

  async getList() {
    this.loaded = false;
    const param: ISendParams = {
      page: this.page,
      // eslint-disable-next-line @typescript-eslint/camelcase
      page_limit: this.limit
    };
    // eslint-disable-next-line @typescript-eslint/camelcase
    this.start_date && (param.start_date = this.start_date);
    // eslint-disable-next-line @typescript-eslint/camelcase
    this.end_date && (param.end_date = this.end_date);
    this.formInline.status && (param.status = this.formInline.status);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const result = await Server.Web.Common.getMail<IMailResult>(param);
    if (!(result instanceof Error)) {
      this.list = result.list;
      this.loaded = true;
    }
  }

  async jumpDetail(id: string, sid: string) {
    this.showDetail = true;
    this.loaded = false;
    const param = {
      id,
      // eslint-disable-next-line @typescript-eslint/camelcase
      sender_id: sid
    };
    const result = await Server.Web.Common.getMailDetail<IMailResult>(param);
    if (!(result instanceof Error)) {
      this.detail = result.list[0];
      this.loaded = true;
    }
  }

  async submitWrite(e: {title: string; content: string}) {
    const result = await Server.Web.Common.sendMail({
      title: e.title,
      message: e.content,
      type: 3,
      receiver: []
    });
    if (!(result instanceof Error)) {
      ElMessage(this.$t('msgCenter__sendSuccess'));
      this.showCreatFrom = false;
    }
  }
}
</script>
<style lang="scss" scoped>
@import '../../../../common/styles/element.scss';
.form-title {
  margin-bottom: 30px;
}
.msg-list {
  justify-content: space-between;
  cursor: pointer;
  padding: 20px 10px;
  &:nth-child(2n-1) {
    background-color: #fbfbfb;
  }
  .content {
    flex-grow: 1;
  }
  dt {
    font-size: 18px;
    font-weight: bold;
    color: #666;
    margin-bottom: 10px;
  }
  dd {
    font-size: 14px;
    color: #999;
    margin: 0;
    max-width: 70%;
  }
  .time {
    color: #999;
    font-size: 12px;
  }
}
</style>
