<template>
  <el-row v-if="!showDetail">
    <el-col :span="24">
      <div class="msg-list disFlex" @click="jumpDetail(v.msnd_id)" v-for="(v, i) in rows" :key="i">
        <div class="content">
          <dt>{{ v.title }}</dt>
          <dd>{{ v.content }}</dd>
        </div>
        <div class="time">{{ v.create_time }}</div>
      </div>
    </el-col>
    <div class="page">
      <el-pagination layout="prev, pager, next" :total="total" v-if="total > 0"> </el-pagination>
    </div>
  </el-row>
  <Content-detail
    v-else
    v-model="showDetail"
    :title="detail.title"
    :time="detail.create_time"
    :content="detail.content"
    :loaded="loaded"
  />
</template>

<script lang="ts">
/* eslint-disable @typescript-eslint/camelcase */
import {INotice, INoticeResult} from '@/model';
import {Format, Server} from '@/services';
import {Options, Vue} from 'vue-class-component';
import createForm from './CreateForm.vue';
import {ContentDetail} from '@/common/web';

@Options({
  components: {
    createForm,
    ContentDetail
  }
})
export default class Public extends Vue {
  page = 1;
  limit = 10;
  total = 0;
  showDetail = false;
  rows: INotice[] = [];
  detail = {
    title: '',
    create_time: '',
    // eslint-disable-next-line @typescript-eslint/camelcase
    content: ''
  };
  created() {
    this.getList();
  }
  async getList() {
    const now = new Date();
    const day29Before = new Date();
    day29Before.setDate(day29Before.getDate() - 29);
    const param = {
      // eslint-disable-next-line @typescript-eslint/camelcase
      start_date: Format.formatYMDLine(now),
      // eslint-disable-next-line @typescript-eslint/camelcase
      end_date: Format.formatYMDLine(day29Before),
      page: this.page,
      limit: this.limit
    };
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const result = await Server.Web.Common.getNotice<INoticeResult>(param);
    this.rows = [
      {
        title: 'aa',
        create_time: '2020-11-11',
        content: 'bbb',
        id: 'a',
        status: 0,
        checked: false
      },
      {
        title: 'aab',
        create_time: '2020-11-11',
        content: 'bbb',
        id: 'ab',
        status: 0,
        checked: false
      },
      {
        title: 'aac',
        create_time: '2020-11-11',
        content: 'bbb',
        id: 'ac',
        status: 0,
        checked: false
      },
      {
        title: 'aad',
        create_time: '2020-11-11',
        content: 'bbb',
        id: 'ad',
        status: 0,
        checked: false
      },
      {
        title: 'aae',
        create_time: '2020-11-11',
        content: 'bbb',
        id: 'ae',
        status: 0,
        checked: false
      },
      {
        title: 'aaf',
        create_time: '2020-11-11',
        content: 'bbb',
        id: 'af',
        status: 0,
        checked: false
      },
      {
        title: 'aag',
        create_time: '2020-11-11',
        content: 'bbb',
        id: 'ag',
        status: 0,
        checked: false
      },
      {
        title: 'aah',
        create_time: '2020-11-11',
        content: 'bbb',
        id: 'ah',
        status: 0,
        checked: false
      },
      {
        title: 'aai',
        create_time: '2020-11-11',
        content: 'bbb',
        id: 'ai',
        status: 0,
        checked: false
      }
    ];
    // if (!(result instanceof Error)) {
    //   this.total = result.pagination.totalNumber;
    // }
  }
  async jumpDetail(id: string) {
    const param = {
      // eslint-disable-next-line @typescript-eslint/camelcase
      id
    };
    const result = await Server.Web.Common.getNoticetDetail<INoticeResult>(param);
    if (!(result instanceof Error)) {
      this.detail = result.list[0];
    }
  }
}
</script>
<style lang="scss" scoped>
@import '../../../../common/styles/element.scss';
.form-title {
  margin-bottom: 30px;
}
.msg-list {
  justify-content: space-between;
  cursor: pointer;
  padding: 20px 10px;
  &:nth-child(2n-1) {
    background-color: #fbfbfb;
  }
  .content {
    flex-grow: 1;
  }
  dt {
    font-size: 18px;
    font-weight: bold;
    color: #666;
    margin-bottom: 10px;
  }
  dd {
    font-size: 14px;
    color: #999;
    margin: 0;
    max-width: 70%;
  }
  .time {
    color: #999;
    font-size: 12px;
  }
}
</style>
