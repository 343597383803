<template>
  <el-row v-if="!showDetail">
    <el-col :span="24" class="text-align-right work-group" v-if="loaded">
      <el-button @click="readAll"> {{ $t('ui_wap_text_070') }}</el-button>
    </el-col>
    <el-col :span="24">
      <div v-if="!loaded">
        <el-skeleton :rows="2" animated v-for="v in 5" :key="v" />
      </div>
      <div
        class="msg-list disFlex"
        @click="jumpDetail(v.msnd_id)"
        v-for="(v, i) in list"
        :key="i"
        v-else
      >
        <div class="content">
          <dt :class="{needRead: v.status == 0}">{{ v.subject }}</dt>
          <dd>{{ v.content }}</dd>
        </div>
        <div class="time">{{ v.send_time }}</div>
      </div>
    </el-col>
    <div class="page">
      <el-pagination
        layout="prev, pager, next"
        :total="total"
        v-if="total > 0"
        @current-change="handlePage"
      >
      </el-pagination>
    </div>
  </el-row>
  <Content-detail
    v-else
    v-model="showDetail"
    :title="detail.subject"
    :time="detail.send_time"
    :content="detail.content"
    :loaded="loaded"
  />
</template>

<script lang="ts">
import {IAnnoucement, IAnnoucementResult} from '@/model';
import {Format, Server} from '@/services';
import {Options, Vue} from 'vue-class-component';
import createForm from './CreateForm.vue';
import {ContentDetail} from '@/common/web';
import {ElMessage} from 'element-plus';

@Options({
  components: {
    createForm,
    ContentDetail
  }
})
export default class Public extends Vue {
  page = 1;
  limit = 10;
  total = 0;
  showDetail = false;
  list: Array<IAnnoucement> = [];
  detail = {
    content: '',
    subject: '',
    // eslint-disable-next-line @typescript-eslint/camelcase
    send_time: ''
  };
  loaded = false;

  created() {
    this.getList();
  }

  async readAll() {
    const result = await Server.Web.Common.setAnnouncementRead({type: 1});
    if (!(result instanceof Error)) {
      ElMessage(this.$t('ui_wap_text_070'));
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      this.getList();
    }
  }

  async getList() {
    this.loaded = false;
    const now = new Date();
    const day29Before = new Date();
    day29Before.setDate(day29Before.getDate() - 29);
    const param = {
      // eslint-disable-next-line @typescript-eslint/camelcase
      start_date: Format.formatYMDLine(now),
      // eslint-disable-next-line @typescript-eslint/camelcase
      end_date: Format.formatYMDLine(day29Before),
      page: this.page,
      limit: this.limit
    };
    const result = await Server.Web.Common.getAnnouncement<IAnnoucementResult>(param);
    if (!(result instanceof Error)) {
      this.list = result.list;
      this.total = result.pagination.totalNumber;
      this.loaded = true;
    }
  }

  async jumpDetail(id: string) {
    this.loaded = false;
    this.showDetail = true;
    const param = {
      // eslint-disable-next-line @typescript-eslint/camelcase
      msnd_id: id
    };
    const result = await Server.Web.Common.getAnnouncementDetail<IAnnoucementResult>(param);
    if (!(result instanceof Error)) {
      this.detail = result.list[0];
      this.loaded = true;
    }
  }

  handlePage(e: number) {
    this.page = e;
    this.getList();
  }
}
</script>
<style lang="scss" scoped>
@import '../../../../common/styles/element.scss';
.form-title {
  margin-bottom: 30px;
}
.work-group {
  margin-bottom: 20px;
}
.msg-list {
  justify-content: space-between;
  cursor: pointer;
  padding: 20px 10px;
  &:nth-child(2n-1) {
    background-color: #fbfbfb;
  }
  .content {
    flex-grow: 1;
  }
  dt {
    font-size: 18px;
    font-weight: bold;
    color: #666;
    margin-bottom: 10px;
    position: relative;
    &.needRead {
      padding-left: 10px;
      &::before {
        content: '';
        width: 5px;
        height: 5px;
        border-radius: 100%;
        background: #dd6161;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 0;
      }
    }
  }
  dd {
    font-size: 14px;
    color: #999;
    margin: 0;
    max-width: 70%;
  }
  .time {
    color: #999;
    font-size: 12px;
  }
}
</style>
