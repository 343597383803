
import {IAnnoucement, IAnnoucementResult} from '@/model';
import {Format, Server} from '@/services';
import {Options, Vue} from 'vue-class-component';
import createForm from './CreateForm.vue';
import {ContentDetail} from '@/common/web';
import {ElMessage} from 'element-plus';

@Options({
  components: {
    createForm,
    ContentDetail
  }
})
export default class Public extends Vue {
  page = 1;
  limit = 10;
  total = 0;
  showDetail = false;
  list: Array<IAnnoucement> = [];
  detail = {
    content: '',
    subject: '',
    // eslint-disable-next-line @typescript-eslint/camelcase
    send_time: ''
  };
  loaded = false;

  created() {
    this.getList();
  }

  async readAll() {
    const result = await Server.Web.Common.setAnnouncementRead({type: 1});
    if (!(result instanceof Error)) {
      ElMessage(this.$t('ui_wap_text_070'));
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      this.getList();
    }
  }

  async getList() {
    this.loaded = false;
    const now = new Date();
    const day29Before = new Date();
    day29Before.setDate(day29Before.getDate() - 29);
    const param = {
      // eslint-disable-next-line @typescript-eslint/camelcase
      start_date: Format.formatYMDLine(now),
      // eslint-disable-next-line @typescript-eslint/camelcase
      end_date: Format.formatYMDLine(day29Before),
      page: this.page,
      limit: this.limit
    };
    const result = await Server.Web.Common.getAnnouncement<IAnnoucementResult>(param);
    if (!(result instanceof Error)) {
      this.list = result.list;
      this.total = result.pagination.totalNumber;
      this.loaded = true;
    }
  }

  async jumpDetail(id: string) {
    this.loaded = false;
    this.showDetail = true;
    const param = {
      // eslint-disable-next-line @typescript-eslint/camelcase
      msnd_id: id
    };
    const result = await Server.Web.Common.getAnnouncementDetail<IAnnoucementResult>(param);
    if (!(result instanceof Error)) {
      this.detail = result.list[0];
      this.loaded = true;
    }
  }

  handlePage(e: number) {
    this.page = e;
    this.getList();
  }
}
