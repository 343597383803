
/* eslint-disable @typescript-eslint/camelcase */
import {IMail, IMailResult} from '@/model';
import {Format, Server} from '@/services';
import {Options, Vue} from 'vue-class-component';
import createForm from './CreateForm.vue';
import {ElMessage} from 'element-plus';
import {ContentDetail} from '@/common/web';

interface ISendParams {
  page: number;
  // eslint-disable-next-line @typescript-eslint/camelcase
  page_limit: number;
  // eslint-disable-next-line @typescript-eslint/camelcase
  start_date?: string;
  // eslint-disable-next-line @typescript-eslint/camelcase
  end_date?: string;
  status?: string;
}
@Options({
  components: {
    createForm,
    ContentDetail
  }
})
export default class Public extends Vue {
  page = 1;

  limit = 10;

  total = 0;

  showDetail = false;

  showCreatFrom = false;

  list: Array<IMail> = [];

  loaded = false;
  // eslint-disable-next-line @typescript-eslint/camelcase
  start_date = '';
  // eslint-disable-next-line @typescript-eslint/camelcase
  end_date = '';

  detail = {
    title: '',
    time: '',
    // eslint-disable-next-line @typescript-eslint/camelcase
    content: ''
  };

  formInline = {
    date: '',
    status: ''
  };

  created() {
    this.getList();
  }
  transTime() {
    // eslint-disable-next-line @typescript-eslint/camelcase
    this.start_date = Format.formatYMDLine(new Date(this.formInline.date[0]));
    // eslint-disable-next-line @typescript-eslint/camelcase
    this.end_date = Format.formatYMDLine(new Date(this.formInline.date[1]));
  }

  async getList() {
    this.loaded = false;
    const param: ISendParams = {
      page: this.page,
      // eslint-disable-next-line @typescript-eslint/camelcase
      page_limit: this.limit
    };
    // eslint-disable-next-line @typescript-eslint/camelcase
    this.start_date && (param.start_date = this.start_date);
    // eslint-disable-next-line @typescript-eslint/camelcase
    this.end_date && (param.end_date = this.end_date);
    this.formInline.status && (param.status = this.formInline.status);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const result = await Server.Web.Common.getMail<IMailResult>(param);
    if (!(result instanceof Error)) {
      this.list = result.list;
      this.loaded = true;
    }
  }

  async jumpDetail(id: string, sid: string) {
    this.showDetail = true;
    this.loaded = false;
    const param = {
      id,
      // eslint-disable-next-line @typescript-eslint/camelcase
      sender_id: sid
    };
    const result = await Server.Web.Common.getMailDetail<IMailResult>(param);
    if (!(result instanceof Error)) {
      this.detail = result.list[0];
      this.loaded = true;
    }
  }

  async submitWrite(e: {title: string; content: string}) {
    const result = await Server.Web.Common.sendMail({
      title: e.title,
      message: e.content,
      type: 3,
      receiver: []
    });
    if (!(result instanceof Error)) {
      ElMessage(this.$t('msgCenter__sendSuccess'));
      this.showCreatFrom = false;
    }
  }
}
