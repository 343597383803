
import {Options, Vue} from 'vue-class-component';
import Public from './components/public.vue';
import Notice from './components/notice.vue';
import Mail from './components/mail.vue';

@Options({
  components: {
    Public,
    Notice,
    Mail
  }
})
export default class Login extends Vue {
  checkIndex = 1;
}
