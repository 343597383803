<template>
  <el-dialog
    :close-on-click-modal="true"
    :close-on-press-escape="true"
    :title="$t('btn_add')"
    width="500px"
    :modelValue="visible"
    @closed="onCancel()"
  >
    <template #footer>
      <el-button key="back" @click="onCancel">{{ $t('btn_cancel') }}</el-button>
      <el-button
        key="submit"
        type="primary"
        :loading="onSubmitLoading"
        @click="onSubmit(modelRef)"
        >{{ $t('btn_submit') }}</el-button
      >
    </template>

    <el-form :model="modelRef" ref="formRef" label-width="80px">
      <el-form-item :label="$t('ui_topic')" prop="title">
        <el-input v-model="modelRef.title" :placeholder="$t('ui_input_msg_topic')" />
      </el-form-item>

      <el-form-item :label="$t('msgCenter__content')" prop="content">
        <el-input
          type="textarea"
          v-model="modelRef.content"
          :placeholder="$t('ui_input_remark_length_limit')"
        />
      </el-form-item>
    </el-form>
  </el-dialog>
</template>
<script lang="ts">
import {Options, Vue} from 'vue-class-component';
@Options({
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    onCancel: {
      type: Function,
      required: true
    },
    onSubmitLoading: {
      type: Boolean,
      required: true
    },
    onSubmit: {
      type: Function,
      required: true
    }
  }
})
export default class Login extends Vue {
  modelRef = {
    title: '',
    content: ''
  };
  resetFields() {
    console.log(123);
  }
}
</script>
<style lang="scss" scoped>
@import '../../../../common/styles/element.scss';
</style>
