
import {Options, Vue} from 'vue-class-component';
@Options({
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    onCancel: {
      type: Function,
      required: true
    },
    onSubmitLoading: {
      type: Boolean,
      required: true
    },
    onSubmit: {
      type: Function,
      required: true
    }
  }
})
export default class Login extends Vue {
  modelRef = {
    title: '',
    content: ''
  };
  resetFields() {
    console.log(123);
  }
}
